export class Logger {
  private readonly prefix: string;

  constructor(name: string) {
    this.prefix = `[${name}]`;
  }

  public debug(...args: any[]) {
    // eslint-disable-next-line no-console
    console.info(this.prefix, ...args);
  }

  public info(...args: any[]) {
    // eslint-disable-next-line no-console
    console.info(this.prefix, ...args);
  }

  public warn(...args: any[]) {
    console.warn(this.prefix, ...args);
  }

  public error(...args: any[]) {
    console.error(this.prefix, ...args);
  }
}
