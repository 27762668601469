import { Logger } from './logger';

const logger = new Logger('Navigation');

export function setupBackNavigation(): void {
  const isSubPage = document.querySelector('[data-pspa-active][data-is-subpage=true]') != null;
  const logoLink = document.querySelector<HTMLElement>('[data-logo-link]')!;
  const backLink = document.querySelectorAll<HTMLElement>('[data-back-link]')!;

  if (isSubPage) {
    backLink.forEach((el: Element) => el.classList.remove('hidden'));
  } else {
    backLink.forEach((el: Element) => el.classList.add('hidden'));
  }

  const parentUrl = document.querySelector('[data-pspa-active][data-parent-url]')?.getAttribute('data-parent-url');

  backLink.forEach((el: Element) => el.setAttribute('href', parentUrl || '/'));
}

export function updateNaviActiveClass(): void {
  const siteId = document.querySelector('[data-pspa-active][data-site-id]')?.getAttribute('data-site-id');
  if (siteId == null) {
    return;
  }

  logger.info('Update navi for site', siteId);

  const activeClass = 'navi-link--active';

  const naviLinks = document.querySelectorAll<HTMLElement>('[data-navi-siteid]');
  naviLinks.forEach((link: HTMLElement) =>
    link.dataset.naviSiteid === siteId ? link.classList.add(activeClass) : link.classList.remove(activeClass)
  );
}
