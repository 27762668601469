import '../styles/style-zig-base.scss';

import {
    Logger,
    RippleEffect,
    setupBackNavigation, setupScrollListener,
    Unregister,
    unregisterEvents,
    updateNaviActiveClass,
} from './lib';
import {PseudoSpa, PspaSite} from './pspa';

import './features/tracking';
import {PopupElement} from './components/popup-info/popup-info.webcomponent';

const logger = new Logger('Main');
let unregisterStack: Unregister[] = [];

window.addEventListener('DOMContentLoaded', () => {
    // register our components
    registerWebComponents();

    const pspa = new PseudoSpa();
    pspa.observe$().subscribe((site: PspaSite) => {
        void setupPage(site.siteId);
    });

    RippleEffect.install(document.body);

    document.addEventListener('historypushstate', () => processPage());
    window.addEventListener('popstate', () => processPage());

    processPage();
});

function processPage(): void {
    document.body.classList.remove('fake-fullscreen'); // fixes remaining css class on IOS browser back button

    updateNaviActiveClass();
    setupScrollListener();
    setupBackNavigation();
}

async function setupPage(currentPage: string): Promise<void> {

    // remove listeners of last page features if available
    if (unregisterStack.length) {
        unregisterEvents(unregisterStack);
    }

    let page = currentPage.substring(0, 5) === 'game-' ? 'game' : currentPage;

    switch (page) {
        case 'contact':
            const contactFeature = await import('./features/contact.feature');
            unregisterStack = contactFeature.setup();
            logger.info(`Setup contact feature on page ${currentPage}.`);
            break;
        case 'index':
            const homeFeature = await import('./features/home.feature');
            unregisterStack = homeFeature.setup();
            logger.info(`Setup home feature on page ${currentPage}.`);
            break;
        case 'game':
            const gameFeature = await import('./features/game.feature');
            unregisterStack = gameFeature.setup();
            logger.info(`Setup game feature on page ${currentPage}.`);
            break;
        case 'games':
            const gameListFeature = await import('./features/gameList.feature');
            unregisterStack = gameListFeature.setup();
            logger.info(`Setup gameListFeature on page ${currentPage}.`);
            break;
        default:
            logger.info(`No special setup for page ${currentPage}.`);
    }
}

function registerWebComponents() {
    customElements.define('popup-info', PopupElement);
}
