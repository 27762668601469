export const Events = {
  getComposed(evt: Event): HTMLElement {
    return evt.composed ? (evt.composedPath()[0] as HTMLElement) : (evt.target as HTMLElement);
  },

  dispatchHistoryPushStateEvent(): void {
    document.dispatchEvent(new HistoryPushStateEvent());
  },

  dispatch(key: string, value: string): void {
    const evt: CustomEvent<string> = new CustomEvent<string>(key, { detail: value });
    document.dispatchEvent(evt);
  },
};

class HistoryPushStateEvent extends CustomEvent<{}> {
  constructor() {
    super('historypushstate', {});
  }
}
