import { documentObserve, Logger } from '../../lib';
import { ClickTrackedEvent, EventInput, LighthouseClient, PageViewTrackedEvent } from 'lighthouse-js';

const logger = new Logger('Tracking');

documentObserve(
  'click',
  '[data-integrationtest-id]',
  event => {
    const eventTarget = event.target as HTMLElement;
    const elementId = eventTarget.closest<HTMLElement>('[data-integrationtest-id]')?.dataset?.integrationtestId;

    if (elementId) {
      logger.info('Got click on event:', elementId);
      trackClick(elementId);
    }
  },
  { capture: true }
);

document.addEventListener('DOMContentLoaded', event => {
  logger.info('Initial navigation to', location.href);
  trackNavigation();
});

document.addEventListener('historypushstate', event => {
  logger.info('Navigate to', location.href);
  trackNavigation();
});
const lighthouseUrl = window.BaseViewModel.lighthouseTrackingUrl;
const lighthouseClient = new LighthouseClient('socas', 'WEB', lighthouseUrl, currentClientId());

function trackClick(elementId: string) {
  const eventInput: EventInput<ClickTrackedEvent> = {
    eventName: 'click',
    clickElement: elementId,
    products: [],
  };

  void lighthouseClient.send(eventInput);
}

function trackNavigation() {
  const eventInput: EventInput<PageViewTrackedEvent> = {
    eventName: 'pageview',
    products: [],
  };

  void lighthouseClient.send(eventInput);
}

function currentClientId(): string {
  function generateClientId(): string {
    let clientId = '';
    for (let i = 0; i < 26; i++) {
      const alphabet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      clientId += alphabet[(Math.random() * alphabet.length) | 0];
    }

    return clientId;
  }

  try {
    let clientId = localStorage.getItem('lighthouse.clientId');
    if (clientId != null) {
      return clientId;
    }

    clientId = generateClientId();
    localStorage.setItem('lighthouse.clientId', clientId);
    return clientId;
  } catch {
    // we dont have local storage, fuck
    return generateClientId();
  }
}

export default {};
