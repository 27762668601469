export type EventCallback = (this: HTMLElement, event: Event) => void;
export type Unregister = () => void;

export function documentObserve(
  eventType: string,
  selector: string,
  callback: EventCallback,
  options?: AddEventListenerOptions
): Unregister {
  const listener = (event: Event) => {
    const eventTarget = event.target as HTMLElement | null;

    const element = eventTarget?.closest(selector);
    if (element == null) {
      return;
    }

    callback.call(element as HTMLElement, event);
  };

  document.addEventListener(eventType, listener, options);

  return () => document.removeEventListener(eventType, listener, options);
}

export function unregisterEvents(unregisterStack: Unregister[]): void {
  if (unregisterStack.length) {
    while (unregisterStack.length) {
      const unregister = unregisterStack.shift();
      if (unregister) {
        unregister();
      }
    }
  }
}
