import { Logger } from './lib';
import { Events } from './events/event-manager';
import { PopupElement } from './components/popup-info/popup-info.webcomponent';
import { BehaviorSubject, Observable } from 'rxjs';

const logger = new Logger('SPA');

export interface PspaSite {
  url: string;
  siteId: string;
}

export class PseudoSpa {
  private readonly spa: BehaviorSubject<PspaSite>;

  private requestId: number = 0;
  private contentContainer: Element;
  private requestedUrl: string | undefined = undefined;

  constructor() {
    const contentContainer = document.querySelector('[data-pspa-content-container]');
    if (contentContainer == null) {
      throw new Error('No content container found');
    }
    const siteId = contentContainer.querySelector('[data-pspa-content]')?.getAttribute('data-site-id') ?? 'index';
    this.spa = new BehaviorSubject<PspaSite>({
      url: location.href,
      siteId,
    });
    this.contentContainer = contentContainer as HTMLElement;
    this.init();
  }

  public observe$(): Observable<PspaSite> {
    return this.spa.asObservable();
  }

  // checks if clicked element has [data-pspa-link][href] attributes
  private async handleLinkClick(evt: MouseEvent): Promise<void> {
    const element = Events.getComposed(evt);
    const testElement = element.closest('[data-pspa-link][href]');

    if (testElement) {
      evt.preventDefault();
      const url = testElement.getAttribute('href')!;

      // if the requestUrl is already known, we don't fetch
      if (location.pathname === url || this.requestedUrl === url) {
        return;
      }

      // trigger background shape tweening to show navigation will change
      Events.dispatch('navigation-will-change', url);

      // if it is a back link, go back in history
      if (url === '/:back') {
        if (history.length > 1) {
          history.go(-1);
          return;
        }
        // if there is no history entry, go to homepage
        return this.requestUrl('/');
      }

      // if there is a section jump in the url, go to the section and dont fire pspa request
      if (url.lastIndexOf('#') >= 0) {
        location.href = url;
        return;
      }
      // otherwise, fire pspa request
      await this.requestUrl(url);
    }
  }

  private async requestUrl(url: string): Promise<void> {
    this.requestedUrl = url;

    const id = ++this.requestId;
    const response = await fetch(url);
    const data = await response.text();

    if (this.requestId === id) {
      return this.handleData(data, response.url);
    }
  }

  private removeRippleArtifacts(el: HTMLElement) {
    const ripples = el.querySelectorAll('span.ripple');
    ripples.forEach((e) => e.parentNode?.removeChild(e));
  }

  private init(): void {
    document.body.addEventListener(
      'click',
      async (evt: MouseEvent) => {
        this.handleLinkClick(evt);
      },
      { capture: true }
    );

    window.addEventListener('popstate', event => {
      if (event.state) {
        this.removePopupInfos();
        Events.dispatch('navigation-will-change', event.state.url);

        const dump = document.createElement('div');
        dump.innerHTML = event.state.html;

        this.removeRippleArtifacts(dump);

        const restoredContent = dump.firstElementChild as HTMLElement;

        this.displayPageTransition(restoredContent, event.state.url);
        document.title = event.state.pageTitle;

        // check if this page has been visited before and scroll to last scrollTop
        const pageScroll: number = event.state.scrollTop;
        if (pageScroll) {
          restoredContent.scrollTo({ top: pageScroll });
        }
      }
    });
  }

  private handleData(htmlContent: string, url: string): void {
    this.requestedUrl = undefined;

    // get the current page title
    const oldTitle = document.title;

    // get the current content container
    const oldContent = document.querySelector('[data-pspa-active]') as HTMLElement;

    // remove popups from dom, as they are not inside content container
    this.removePopupInfos();

    // parse the response html into a dom tree
    const dump = document.createElement('div');
    dump.innerHTML = htmlContent;

    // get the actual active content from
    const newContent = dump.querySelector('[data-pspa-content][data-pspa-active]');

    // update the page title
    const title = dump.querySelector('title')?.innerText || document.title;
    document.title = title;

    // update the authenticated state
    const isAuthenticated =
      dump.querySelector('[data-is-authenticated]')?.getAttribute('data-is-authenticated') ?? 'false';
    const main = document.querySelector('[data-is-authenticated]') as HTMLElement;
    if (main) {
      main.dataset.isAuthenticated = isAuthenticated;
    }

    if (newContent) {
      window.history.replaceState(
        {
          html: oldContent.outerHTML,
          pageTitle: oldTitle,
          scrollTop: oldContent.scrollTop,
        },
        ''
      );

      const historyState = {
        html: newContent.outerHTML,
        pageTitle: title,
      };

      window.history.pushState(historyState, '', url);

      this.displayPageTransition(newContent as HTMLElement, url);

      Events.dispatchHistoryPushStateEvent();
    } else {
      // something went wrong, just navigate to the target
      location.href = url;
    }
  }

  private removePopupInfos(): void {
    for (const popup of Array.from(document.querySelectorAll('popup-info'))) {
      if (popup instanceof PopupElement) {
        if (popup.isOpen) {
          popup.close();
        }
      }
    }
  }

  private setPageClass(): void {
    const target = document.querySelector('[data-pspa-content-container]') as HTMLElement;
    const site = location.pathname.split('/')[1] || 'index';
    target.className = 'content-wrapper content-wrapper--' + site;
    target.style.setProperty('--background-position-index', '0px');
  }

  private setLoginButton(newContent: HTMLElement) {
    const loginButtons = newContent.querySelectorAll('[data-login-button]');

    const ls = localStorage.getItem('loginCustomer');
    const isTipp24Customer = ls === 'TIPP24_COM';
    const isLotto24Customer = ls === 'LOTTO24_DE' || !ls;

    loginButtons.forEach((btn: Element) => {
      const type = btn.getAttribute('data-btn-type')!;
      if (type === 't24' && isTipp24Customer) {
        btn.classList.remove('hidden');
      }
      if (type === 'l24' && isLotto24Customer) {
        btn.classList.remove('hidden');
      }
    });
  }

  private displayPageTransition(newContent: HTMLElement, url: string) {
    this.setLoginButton(newContent);
    this.setPageClass();

    const oldContent = document.querySelector('[data-pspa-active]')!;

    // remove the 'active' marker
    oldContent.removeAttribute('data-pspa-active');

    oldContent.classList.add('oldPageTransition');

    // insert newContent after oldContent
    this.contentContainer.insertBefore(newContent, oldContent.nextSibling);

    // animate the new content
    newContent.classList.add('newPageTransition');

    newContent.addEventListener(
      'animationend',
      () => {
        // get rid of the old content
        this.contentContainer.removeChild(oldContent);

        // remove the animation class from the current content
        newContent.classList.remove('newPageTransition');

        // inform others about site change
        this.spa.next({
          url,
          siteId: newContent?.getAttribute('data-site-id') ?? '',
        });
      },
      { once: true }
    );
  }
}
