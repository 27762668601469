import html from './popup-info.webcomponent.html';
import css from './popup-info.webcomponent.scss';

import {PopupCloseEvent, PopupOpenEvent, popupStack} from './popup-stack';
import {Logger} from '../../lib';
import {registerClickAction} from './popup-handler';

let initialzedStack = false;

const logger = new Logger('Popup');

export class PopupElement extends HTMLElement {
    readonly dialog: HTMLDialogElement;

    public static readonly observedAttributes = ['modifier'];
    public isOpen: boolean = false;

    constructor() {
        super();

        if (!initialzedStack) {
            initialzedStack = true;
            popupStack(document);
            registerClickAction();
        }

        // setup markup
        const shadow = this.attachShadow({mode: 'open'});
        shadow.innerHTML = html;
        shadow.querySelector('style')!.textContent = css;

        // work with a html dialog element
        this.dialog = shadow.querySelector('dialog') as HTMLDialogElement;

        // handle animation end
        this.dialog.addEventListener('animationend', event => {
            logger.debug('Animation ended: ', event.animationName);

            const name = /^([^-]+)/.exec(event.animationName)?.[1];
            if (name != null) {
                // remove animation class
                this.dialog.classList.remove(`anim-${name}`);
            }

            if (name === 'restore') {
                this.dialog.style.transform = 'translateY(0px)';
            }

            if (name === 'close') {
                // the close animation finished, we will close the dialog now.
                this.dialog.close();
            }
        });

        const pug = this.dialog.querySelector('[data-pug]') as HTMLElement;
        this.setupDragAndDrop(pug);

        const closeBtn = this.dialog.querySelector('[data-close-popup]') as HTMLElement;
        closeBtn.addEventListener('click', this.close.bind(this));
    }

    attributeChangedCallback(name: string, oldValue: string | null, newValue: string) {
        if (name === 'modifier') {
            const body = this.dialog.querySelector<HTMLElement>('.popup-body');
            body?.classList.add('popup-body--' + newValue);
        }
    }

    public open(modal: boolean = false) {
        if (this.isOpen) {
            return;
        }
        // start opening animation
        this.dialog.style.transform = '';
        this.dialog.classList.add('anim-open');

        if (modal) {
            this.dialog.showModal();
        } else {
            this.dialog.show();
        }

        // inform everyone, that a new popup was created
        this.dispatchEvent(new PopupOpenEvent());
        this.isOpen = true;
    }

    public close() {
        if (!this.isOpen) {
            return;
        }
        // start close animation
        this.dialog.classList.add('anim-close');

        // inform everyone, that this popup is closing now
        this.dispatchEvent(new PopupCloseEvent());
        this.isOpen = false;
    }

    private setupDragAndDrop(pug: HTMLElement) {
        let startY: number | null = null;

        this.dialog.addEventListener('pointerdown', event => {
            const target = event.target as HTMLElement | null;
            if (target !== pug) {
                return;
            }

            if (event instanceof PointerEvent && startY == null) {
                logger.info('Starting drag process');
                event.preventDefault();
                startY = event.clientY;

                // register pointer events
                document.addEventListener('pointerup', pointerup);
                document.addEventListener('pointermove', pointermove);
            }
        });

        const pointermove = (event: PointerEvent) => {
            event.preventDefault();

            const offY = Math.max(0, event.clientY - startY!);
            this.dialog.style.transform = `translateY(${offY}px)`;
        };

        const pointerup = (event: PointerEvent) => {
            event.preventDefault();

            logger.info('Pointer is going up.');
            const height = this.dialog.getBoundingClientRect().height;

            // if we have moved the dialog at least half it height or 64px (what ever is less),
            // we will close it. If not, we animate back into its normal position.
            const threshold = Math.min(64, height / 2);
            if (event.clientY - startY! > threshold) {
                this.close();
            } else {
                // restore back to initial position;
                this.dialog.classList.add('anim-restore');
            }

            // unregister pointer events
            document.removeEventListener('pointermove', pointermove);
            document.removeEventListener('pointerup', pointerup);

            // stop dragging process
            startY = null;
        };
    }
}
