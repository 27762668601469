import { Events } from '../events/event-manager';

export class RippleEffect {
  constructor(px: number, py: number, button: Element) {
    this.ripple(px, py, button);
  }

  private ripple(px: number, py: number, button: Element): void {
    const ripple = document.createElement('span');
    const modifier = button.getAttribute('data-ripple-effect') ?? '';

    let rippleElement: Element = button;
    // if content is a css class selector, use this element for the ripple
    if (modifier.substring(0, 1) === '.') {
      rippleElement = document.querySelector(modifier) ?? button;
    }
    const bounds = rippleElement.getBoundingClientRect();
    const x = px - bounds.x;
    const y = py - bounds.y;
    const w = bounds.width;

    ripple.className = modifier ? 'ripple ripple--' + modifier : 'ripple';
    ripple.style.left = x + 'px';
    ripple.style.top = y + 'px';
    ripple.style.setProperty('--scale', w.toString());

    rippleElement.appendChild(ripple);

    ripple.addEventListener('animationend', () => rippleElement.removeChild(ripple));
  }

  static install(root: HTMLElement) {
    root.addEventListener('pointerdown', event => {
      const element = Events.getComposed(event);
      const rippleContainer = element.closest('[data-ripple-effect]');

      if (rippleContainer) {
        new RippleEffect(event.pageX, event.pageY, rippleContainer);
      }
    });
  }
}
