import {CounterBadgeElement} from '../components/counter-badge/counter-badge.webcomponent';
import {sleep} from 'zig-js';

export async function animateWinnings(original: HTMLElement, badge: HTMLElement) {
    const badges = badge.querySelectorAll('counter-badge') as unknown as CounterBadgeElement[];

    original.appendChild(badge);

    for (const badge of badges) {
        const type: string = badge.getAttribute('type')!;
        const value: number = +badge.getAttribute('value')!;
        const fromElement: HTMLElement = original.querySelector('[data-icon]')!;
        const toElement: HTMLElement = document.querySelector('.header counter-badge[type="' + type + '"] [data-icon]')!;
        const boundsFrom = fromElement.getBoundingClientRect();

        await animateFromTo(
            boundsFrom,
            toElement.getBoundingClientRect(),
            type,
            value);
    }
}

async function animateFromTo(fromRect: DOMRect, toRect: DOMRect, type: string, amount: number) {
    function createSprite(index: number = 0) {
        const rotation = Math.random() * 720 - 360;
        const scale = Math.random() + 0.5;

        const delay = Math.random() * 250;
        const duration = Math.random() * 500 + 250;

        const el = document.createElement('span');
        el.style.setProperty('--top-animation-left-start', fromRect.x + 'px');
        el.style.setProperty('--top-animation-top-start', fromRect.y + 'px');
        el.style.setProperty('--top-animation-left-end', toRect.x + 'px');
        el.style.setProperty('--top-animation-top-end', toRect.y + 'px');

        el.style.setProperty('--top-animation-delay', `${delay}ms`);
        el.style.setProperty('--top-animation-duration', `${duration}ms`);

        el.style.setProperty('--top-animation-30-scale', scale.toString());
        el.style.setProperty('--top-animation-30-translate-x', `${Math.random() * 50 - 25}px`);
        el.style.setProperty('--top-animation-30-translate-y', `${Math.random() * 50 - 25}px`);
        el.style.setProperty('--top-animation-60-scale', scale.toString());
        el.style.setProperty('--top-animation-60-translate-x', `${Math.random() * 50 - 25}px`);
        el.style.setProperty('--top-animation-60-translate-y', `${Math.random() * 50 - 25}px`);
        el.style.setProperty('--top-animation-100-rotate', `${rotation}deg`);

        el.className = 'icon icon--animated icon--' + type;

        el.addEventListener('animationend', animationDone);
        document.body.appendChild(el);

        function animationDone(): void {
            document.body.removeChild(el);
            el.removeEventListener('animationend', animationDone);
        }
    }

    for (let i = 0; i < Math.min(50, amount); i++) {
        createSprite(i);
    }
    await sleep(750);
}
