import {Logger} from '../../lib';
import {PopupElement} from './popup-info.webcomponent';

const logger = new Logger('PopupStack');

export function popupStack(document: Document) {
    const stack: PopupElement[] = [];

    function transitionDone() {
        document.body.removeEventListener('transitionend', transitionDone);
        document.body.classList.remove('body-transition');
    }

    function blur() {
        const method = stack.length ? 'add' : 'remove';
        document.body.classList.add('body-transition');
        document.body.classList[method]('popup-open');

        document.body.addEventListener('transitionend', transitionDone);
    }

    document.addEventListener('popup-open', event => {
        const popup = event.target as PopupElement;
        logger.info('Adding to stack:', popup);

        stack.push(popup);
        blur();
    });

    document.addEventListener('popup-close', event => {
        const popup = event.target as PopupElement;

        const idx = stack.indexOf(popup);
        if (idx !== -1) {
            logger.info('Removing from stack:', popup);
            stack.splice(idx, 1);
            blur();
        }
    });

    document.addEventListener('keydown', event => {
        if (event.code === 'Escape') {
            const popup = stack.pop();

            if (popup != null) {
                event.preventDefault();

                logger.info('Escape was pressed, closing popup:', popup);
                popup.close();

                blur();
            }
        }
    });

    document.addEventListener('pointerdown', event => {
        const element = event.target as HTMLElement;

        if (element instanceof PopupElement) {
            const rect = element.dialog.getBoundingClientRect();

            const outside =
                event.clientX < rect.left ||
                event.clientX > rect.right ||
                event.clientY < rect.top ||
                event.clientY > rect.bottom;

            if (outside) {
                // stop the event from reaching the target component
                event.preventDefault();
                event.stopImmediatePropagation();

                logger.info('Clicked outside of popup, closing now.');
                element.close();
            }
        }
    });
}

export class PopupOpenEvent extends CustomEvent<void> {
    constructor() {
        super('popup-open', {bubbles: true});
    }
}

export class PopupCloseEvent extends CustomEvent<void> {
    constructor() {
        super('popup-close', {bubbles: true});
    }
}
