import { PopupElement } from '../components/popup-info/popup-info.webcomponent';
import {Events} from '../events/event-manager';

export function setBalance(coins: number, tickets: number): void {
  for (const elCoins of Array.from(document.querySelectorAll('[data-coins]'))) {
    elCoins.setAttribute('value', coins.toString());
  }
  for (const elTickets of Array.from(document.querySelectorAll('[data-tickets]'))) {
    elTickets.setAttribute('value', `${tickets}`);
  }
  for (const elCoins of Array.from(document.querySelectorAll('[data-stats-coins]'))) {
    elCoins.textContent = coins.toString();
  }
  for (const elTickets of Array.from(document.querySelectorAll('[data-stats-tickets]'))) {
    elTickets.textContent = `${tickets}`;
  }
  Events.dispatch('coins-update-event', 'true');
}

export function setupScrollListener(): void {
  const header = document.querySelector('[data-site-header]') as HTMLElement;
  const el = document.querySelector('[data-pspa-active]') as HTMLElement;
  const target = document.querySelector('[data-pspa-content-container]') as HTMLElement;

  const site = el.dataset?.siteId ?? 'index';
  target.className = 'content-wrapper content-wrapper--' + site;

  if (el.scrollTop < 5) {
    header.style.setProperty('--header-alpha', '0');
  }
  el.addEventListener('scroll', evt => {
    const width = window.innerWidth;

    let a = '-' + el.scrollTop / 5 + 'px';
    let alpha = Math.min(1, el.scrollTop / header.offsetHeight);

    if (width > 599) {
      a = '0px';
      alpha = 0;
    }
    header.style.setProperty('--header-alpha', alpha.toString());
  });
}
