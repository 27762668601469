import { PopupElement } from './popup-info.webcomponent';

export function registerClickAction() {
  document.addEventListener('click', event => {
    const target = event.target as HTMLElement;

    if (!target.hasAttribute('data-open-popup')
        && !target.hasAttribute('data-close-popup')
        && (target.classList.contains('button') || target.tagName === 'BUTTON' || target.tagName === 'A')) {
      return;
    }

    const link = target.closest('[data-open-popup]') as HTMLElement | null;
    if (link != null) {
      const name = link.dataset.openPopup;
      const popup = document.querySelector(`popup-info[name='${name}']`) as PopupElement | null;
      if (popup != null) {
        popup.open(true);
      }
    }

    const close = target.closest('[data-close-popup]') as HTMLElement | null;
    if (close != null) {
      const popup = target.closest('popup-info') as PopupElement | null;
      if (popup != null) {
        popup.close();
      }
    }
  });
}
